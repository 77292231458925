import React from 'react';

function video(embed, title) {
  return (
    <div className="video-embed">
      <iframe width="560" height="315" src={embed} frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen></iframe>
      <h3>{title}</h3>
    </div>
  );
};

const americaDay = video("https://www.youtube.com/embed/GssSPVUy2ak", `America Day`);
const live2015 = video("https://www.youtube.com/embed/50FtCkw18UU", `Grunt (Live)`);
const pizza = video("https://www.youtube.com/embed/-UBXB3GvUUk", `Literally Just a Recipe for Pizza`);

export default function videoSection() {
  return (
    <div id="video" className="video-section black">
      <h2>VIDEO</h2>
      {pizza}
      {americaDay}
      {live2015}
    </div>
  );
};
