import React from 'react';
import { newsfeed } from './newsfeed.js';

function article(newsArticle) {
  return (
    <article className="news-article">
      <h3 className="article-title">{newsArticle.title}</h3>
      <span className="article-subtitle">{newsArticle.date}</span>
      <p>{newsArticle.body}</p>
    </article>
  )
}

export default function news() {
  return (
    <section className="news-section">
      <h2>NEWS</h2>
      <div className="article-container">
        {newsfeed.map(newsArticle => article(newsArticle))}
      </div>
    </section>
  );
};
