import React from 'react';

const images = [
{src: "Megacoop2.jpg", alt: "Megacoop"},
{src: "DigiMeadow.jpg", alt: "Loading Outdoors"},
{src: "Fish-and-Cat.jpg", alt: "Social Distancing"},
{src: "STOOFILAGE.jpg", alt: "Drunk Party"},
{src: "TOOTILAGE-Band-001-3k.jpg", alt: "Band Lineup"},
{src: "TOOTILAGE-Band-in-a-Box-003b.jpg", alt: "Action Photo"}
];

export default class Gallery extends React.Component {
  constructor(props){
    super(props);
    this.state = { fullscreenPic: "None" };
  }

  handleClick(e, picName) {
    e.preventDefault();
    if (this.state.fullscreenPic != picName) {
      this.setState(state => ({
        fullscreenPic: picName
      }));
    } else {
      this.setState(state => ({
        fullscreenPic: "None"
      }));
    };
  };

  galleryImage(props) {
    if (this.state.fullscreenPic != props.alt){
      return (
        <div className="image-container">
          <img src={props.src} alt={props.alt}
          onClick={e => this.handleClick(e, props.alt)}
          className="gallery-pic" />
        </div>
      );
    } else {
      return (
        <div className="image-container">
          <img src={props.src} alt={props.alt}
          onClick={e => this.handleClick(e, props.alt)}
          className="gallery-pic fullscreen" />
        </div>
      );
    }
  };

  render() {
    let imageList;
    if (this.state.fullscreenPic !== "None") {
      imageList = this.galleryImage(images.filter(image => this.state.fullscreenPic == image.alt)[0]);
    } else {
      imageList = images.map(image => this.galleryImage(image))
    }
    return (
      <div id="gallery" className="gallery-section">
        <h2 className="title">GALLERY</h2>
        {imageList}
      </div>
    );
  };
};
