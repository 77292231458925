import React from 'react';

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      body: ""
    };
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  };

  render() {
    return(
      <form className="contact-form">
        <label className="subject-input">
          Subject:
          <input value={this.state.subject} name="subject" onChange={this.handleChange} />
        </label>
        <label className="body-input">
          Body:
          <textarea value={this.state.body} name="body" onChange={this.handleChange} />
        </label>
        <a href={`mailto:tootilageband@gmail.com?subject=${this.state.subject}&body=${this.state.body}`} className="contact-submit">Submit</a>
      </form>
    );
  };
};

export default function contact() {
  return (
    <div id="contact" className="contact-section">
      <h2>CONTACT</h2>
      <p>Send us an email! Use the form below:</p>
      <EmailForm />
    </div>
  )
}
