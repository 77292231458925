import React from 'react';
import './App.css';
import { NavStrip, StreamStrip } from './Nav';
import News from './News';
import Video from './Video';
import Bio from './Bio';
import Gallery from './Gallery';
import Contact from './Contact';

function App() {
  return (
    <div className="main-page">
      <img src="Tootilage-logo_alpha.png" alt="Tootilage Logo" className="logo-pic" />
      <div className="nav-section">
        <NavStrip />
        <div className="divider"></div>
        <img src="TOOTILAGE Nap 002.jpg" alt="Tootilage in the Flesh" className="band-pic" />
        <StreamStrip />
      </div>
      <News />
      <Video />
      <Bio />
      <Gallery />
      <Contact />
    </div>
  );
}

export default App;
