import React from 'react';
import { facebookIconSVG, instaIconSVG, youtubeIconSVG } from './svg_images'

function NavLink(props) {
  if (props.newTab) {
    return (
      <li className="nav-link">
      <a href={props.url} target= "_blank">{props.linkName}</a>
      </li>
    );
  } else {
    return (
      <li className="nav-link">
      <a href={props.url}>{props.linkName}</a>
      </li>
    );
  }

};

export function NavStrip() {
  return (
    <ul className="nav strip title-text">
      <NavLink url="/#video" linkName="VIDEO" />
      <NavLink url="/#bio" linkName="BIO" />
      <NavLink url="/#gallery" linkName="GALLERY" />
      <NavLink url="/#contact" linkName="CONTACT" />
      <NavLink url="https://www.facebook.com/tootilage/" newTab="true" linkName={facebookIconSVG} />
      <NavLink url="https://www.instagram.com/tootilage/" newTab="true" linkName={instaIconSVG} />
      <NavLink url="https://www.youtube.com/channel/UCcXYHzayxV-H0NyXTTdw-QA" newTab="true" linkName={youtubeIconSVG} />
    </ul>
  );
};

export function StreamStrip() {
  return (
    <ul className="stream strip title-text">
      <NavLink url="https://open.spotify.com/artist/3Q22UVbJiiZSNGaYcc9Vjr?si=BD5VFRLGQO6PUF-VVk9Umg" newTab="true" linkName="SPOTIFY" /> |
      <NavLink url="https://music.apple.com/us/artist/tootilage/1520137617" newTab="true" linkName="APPLE MUSIC" /> |
      <NavLink url="https://play.google.com/store/music/artist?id=Ajnie5bbeqtwittfgjphtqrf6yi" newTab="true" linkName="GOOGLE PLAY" /> |
      <NavLink url="https://www.deezer.com/us/artist/98732682" newTab="true" linkName="DEEZER" />
    </ul>
  );
}
