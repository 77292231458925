import React from 'react';

const bioText = `Tootilage is what happens when some 90s alt-rock-obsessed guys
team up with a comedian to make music. Guitarist-vocalist Ace Salisbury, bassist
Donovan Dwyer, and wildman Max Rafferty formed Tootilage in 2014 in Brooklyn, NY,
and the result was an explosion of sonic nostalgia with an absurdist twist. Their
first album "Spelled Correctly" would lead critics to question the band's reading
level. Their follow-up EP "Meanderthal" was injured in a boating accident and was
never released. Fresh out of the studio recording their second full-length album
"Evercoop," Tootilage hopes to resonate with others who can't remember what day
of the week it is because of the pandemic. Stupidly relatable, deceptively
profound, and probably hungry, Tootilage is here to rock.`;

export default function bio() {
  return (
    <div id="bio" className="bio-section">
      <h2>BIO</h2>
      <p>{bioText}</p>
    </div>
  );
};
