import React from 'react';

export const newsfeed = [
  {
    title: 'NEW VIDEO',
    date: 'August 4th, 2020',
    body: <div className="article-body">
      Check out our video for "Literally Just a Recipe for Pizza!"
      <iframe width="560" height="315"
      src="https://www.youtube.com/embed/-UBXB3GvUUk" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
    </div>
  },
  {
    title: '"AMERICA DAY" VIDEO',
    date: 'July 7th, 2020',
    body: <div className="article-body">
      The America Day video and single have officially been released!
      <div className="cover-art-container">
        <img src='America-Day-cover-001-3k.jpg'
        title="America Day Cover Art"
        className="cover-art"/>
      </div>
      You can listen to it on any of the following platforms:
      <ul className="unstyled-list">
        <li>
          <a href="https://open.spotify.com/album/5xn8X7Q1KnHdDCPlcRqzlc"
          target="_blank">SPOTIFY</a>
        </li>
        <li>
          <a href="https://music.apple.com/us/album/america-day-single/1520142317"
          target="_blank">APPLE MUSIC</a>
        </li>
        <li>
          <a href="https://www.deezer.com/us/album/156610092"
          target="_blank">DEEZER</a>
        </li>
        <li>
          <a href="https://play.google.com/store/music/album/Tootilage_America_Day?id=B7zrvglkksetbi6xswavxwmhddq"
          target="_blank">GOOGLE PLAY</a>
        </li>
      </ul>
    </div>
  }
];
